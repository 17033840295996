import styled from "styled-components";
import {ButtonTheme} from "./interface";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
  width: 100%;
  row-gap: 8px;
`;

const Button = styled.button<{
  theme: ButtonTheme;
}>`
  height: 42px;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  min-width: 200px;
  text-align: center;
  ${(props) => {
    switch (props.theme) {
      case "secondary":
        return `
          border: 1px solid #ADB5BD;
          border-radius: 6px;
          background: rgba(255, 255, 255, 0);
          color: #04171B;
        `;
      case "primary":
      default:
        return `
          border: 0;
          border-radius: 6px;
          background-color: #218099;
          color: #FFFFFF;
        `;
    }
  }}
  &:hover {
    ${(props) => {
      switch (props.theme) {
        case "secondary":
          return `
          // background: #FFFFFF;
        `;
        case "primary":
        default:
          return `
          background-color: #235667;
        `;
      }
    }}
  }

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  font-feature-settings: "liga" off, "clig" off;

  // Focus Override - Accessibility
  &:focus {
    border-color: var(--semantic-informational);
  }
  // Disabled Override
  &:disabled {
    border-radius: 6px;
    border: 1px solid #adb5bd;
    background: #f8f9fa;
    color: #868e96;
    cursor: not-allowed;
  }

  // Transition
  transition: color 175ms, font-size 175ms, background-color 175ms,
    border-color 175ms;
`;

const Error = styled.p`
  color: orangered;
  font-size: 12px;
  font-family: Inter-Regular, serif;
  font-weight: 400;
  padding: 0;
  margin: 0;
`;

export {Container, Button, Error};
