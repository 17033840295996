import {handleAddressSearch} from "./google-maps";
import {useCallback, useState} from "react";
import {IAddressDetails} from "@natomas-org/core";
import {useDeepEffect} from "src/components/_shared/hooks/useDeepEffect";

export const useAddress = (address?: string) => {
  const [details, setDetails] = useState<IAddressDetails | null>(null);

  const captureSearch = useCallback((details: IAddressDetails | null) => {
    setDetails(details);
  }, []);

  useDeepEffect(() => {
    if (address) {
      handleAddressSearch(address, captureSearch, true);
    } else {
      setDetails(null);
    }
  }, [address]);

  return {
    raw: address,
    details,
  };
};
