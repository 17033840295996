import React, {useState} from "react";
import {ContentWrapper} from "./style";
import {DefaultError} from "../../2-component/Input/generic/DefaultError";
import firebase from "firebase";
import Text from "../../2-component/Text";
import ResetPasswordForm from "../../1-container/forms/ResetPasswordForm";
import Button from "../../2-component/Button";

const ResetPasswordContent = (props: {toLogin: () => void; email?: string}) => {
  const [localError, setLocalError] = useState<string>();
  const [sent, setSent] = useState<boolean>(false);
  const handleSubmit = async (values: any) => {
    return firebase
      .auth()
      .sendPasswordResetEmail(values["email"])
      .then(() => {
        setSent(true);
      })
      .catch((error) => {
        setLocalError("Error sending password reset email: " + error.message);
      });
  };

  return (
    <ContentWrapper>
      {sent ? (
        <>
          <Text
            text={
              "Email sent. Please check your email for instructions on how to reset your password."
            }
            style={{
              textAlign: "center",
              fontSize: "14px !important",
              lineHeight: "18px !important",
            }}
          />
          <Button
            id={"back-to-login-options"}
            label={"Go back"}
            theme={"secondary"}
            type={"button"}
            onClick={props.toLogin}
          />
        </>
      ) : (
        <>
          <Text
            text={
              "Please enter the email address associated with your Villa account. We’ll send you an email with instructions on how to reset your password."
            }
            style={{
              textAlign: "center",
              fontSize: "14px !important",
              lineHeight: "18px !important",
            }}
          />
          <DefaultError
            id={"reset-password-by-email-error"}
            error={localError}
          />
          <ResetPasswordForm
            onSubmit={handleSubmit}
            buttons={[
              {
                id: "back-to-login-options",
                label: "Go back",
                theme: "secondary",
                type: "button",
                onClick: props.toLogin,
              },
              {
                id: "reset-password-by-email-button",
                label: "Reset password",
                type: "submit",
              },
            ]}
          />
        </>
      )}
    </ContentWrapper>
  );
};

export default ResetPasswordContent;
