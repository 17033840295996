import React from "react";
import Form from "../../2-component/Form/form";
import {InputProps} from "../../2-component/Input/interface";
import {FormProps} from "../../2-component/Form/interface";

const id = "marketing_channel";

const MarketingChannelForm = (props: FormProps) => {
  const inputs: InputProps[] = [
    {
      id: id,
      type: "radio",
      label: "Where did you hear about us?",
      required: true,
      selectionProps: [
        {value: "mail_in_mailbox", label: "Mail in mailbox"},
        {value: "nextdoor", label: "Nextdoor"},
        {
          value: "facebook_or_instagram_marketing",
          label: "Facebook or Instagram",
        },
        {value: "word_of_mouth", label: "Word of mouth"},
        {value: "youtube", label: "YouTube"},
        {value: "search_engine", label: "Search engine (e.g. Google, Bing)"},
        {value: "news_or_tv", label: "News or TV"},
        // {value: "online_article", label: "Online Article"},
        {value: "twitter_marketing", label: "Twitter"},
        {value: "tiktok_marketing", label: "TikTok"},
        {value: "other_marketing", label: "Other"},
      ],
    },
  ];
  return (
    <Form
      key={"confirm-marketing-channel-form"}
      inputs={inputs}
      onSubmit={props.onSubmit}
      buttons={props.buttons}
    />
  );
};

export default MarketingChannelForm;
