import React from "react";
import Form from "../../2-component/Form/form";
import {InputProps} from "../../2-component/Input/interface";
import {ButtonProps} from "../../2-component/Button/interface";

const id = "customer_type";

const inputs: InputProps[] = [
  {
    id: id,
    type: "radio",
    label: "",
    required: true,
    selectionProps: [
      {value: "home-owner", label: "Homeowner"},
      {value: "future-home-owner", label: "Future homeowner"},
      {
        value: "investor-multiple",
        label: "Investor looking to build multiple units",
      },
      {
        value: "investor-single",
        label: "Investor looking to build  a single unit",
      },
      {
        value: "primary",
        label: "Parcel owner looking to build a primary residence",
      },
      {value: "agent", label: "Real estate agent"},
      {value: "other", label: "Other"},
    ],
  },
];

const CustomerTypeForm = (props: {
  buttons: ButtonProps[];
  onSubmit: (props: string) => void;
}) => {
  return (
    <Form
      key={"confirm-customer-type-form"}
      label={""}
      inputs={inputs}
      onSubmit={(values) => {
        props.onSubmit(values[id]);
      }}
      buttons={[
        ...props.buttons,
        {
          id: "customer-type-continue",
          label: "Continue",
          type: "submit",
        },
      ]}
    />
  );
};

export default CustomerTypeForm;
