import LogRocket from "logrocket";
import {IAddressDetails, IProduct} from "@natomas-org/core";

interface Ecommerce {
  currency: string;
  value: number;
  items: {
    item_id: string;
    item_name: string;
    quantity: number;
    price: number;
  }[];
}
interface Location {
  address_line_1: string;
  address_line_2: string;
  city: string;
  state_code: string;
  zip: string;
  country_code: string;
}
interface User {
  id: string;
  email: string;
  customer_segment: "D2C" | "SMB B2B"; // form type
  phone?: string;
}
interface DataLayerEvent {
  event?: string; // Optional field that specifies the event type
  event_name?: string; // Optional field that specifies the event name
  form_entry_point?: string; // Optional field that specifies the form entry point
  ecommerce?: Ecommerce | null; // Optional field that specifies the event type
  location?: Location;
  user?: User;
}

// The dataLayer is usually an array of DataLayerEvent objects
interface DataLayer extends Array<DataLayerEvent> {}

// Extend the Window interface to include dataLayer
interface Window {
  dataLayer: DataLayer;
}

// Capture form view
export const captureFormView = async (
  user: User,
  address?: IAddressDetails,
  product?: IProduct
) => {
  const {ecommerce, location} = getEventValues(product, address);
  return captureFormEvent({
    event: "gtmEvent",
    event_name: "form_view",
    form_entry_point: getEventEntryPoint(),
    ecommerce: ecommerce,
    location: location,
    user: user,
  });
};

export const captureFormComplete = async (
  user: User,
  address?: IAddressDetails,
  product?: IProduct
) => {
  const {ecommerce, location} = getEventValues(product, address);
  return captureFormEvent({
    event: "gtmEvent",
    event_name: "form_complete",
    form_entry_point: getEventEntryPoint(),
    ecommerce: ecommerce,
    location: location,
    user: user,
  });
};

const getEventValues = (product?: IProduct, address?: IAddressDetails) => {
  const ecommerce: Ecommerce | null = !product
    ? null
    : {
        currency: "USD",
        value: product?.price?.price ?? 0,
        items: [
          {
            item_id: product?.id ?? "",
            item_name: product?.title?.toLowerCase() ?? "",
            quantity: 1,
            price: product?.price?.price ?? 0,
          },
        ],
      };
  const location: Location | undefined = !address
    ? undefined
    : {
        address_line_1: `${address.street_number} ${address.street}`,
        address_line_2: "",
        city: address.city,
        state_code: address.state,
        zip: address.zip,
        country_code: address.country,
      };
  return {
    ecommerce,
    location,
  };
};

const getEventEntryPoint = () => {
  let path = window.location.pathname;

  // Remove leading slashes
  let cleanedPath = path.replace(/^\/+/, "");

  return cleanedPath;
};

const captureFormEvent = async (event: DataLayerEvent) => {
  try {
    const w = window as unknown as Window;
    w.dataLayer.push({ecommerce: null});
    w.dataLayer.push(event);
    LogRocket.info("captureFormEvent", event);
  } catch (error) {
    LogRocket.error("captureFormEventError", error, event);
  }
};
