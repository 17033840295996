import React, {useEffect, useState} from "react";
import logo from "../../../../assets/logos/villa-logo-white.png";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {
  ImageContainer,
  LoadingPanelContainer,
  LoadingPanelSpinner,
  SpinnerContainer,
} from "./styles";
import {WHITE} from "../../colors";
import LoggedInAs from "../../../../0-app/0-page/auth-content/LoggedInAs";
import {useCurrentUser} from "../../hooks/useCurrentUser";
import {logOut} from "../../../../database/firebase";
import {useNavigation} from "../../hooks/useNavigation";
import {NavigationPaths} from "../../hooks/useNavigation/paths";
import Text from "../../../../0-app/2-component/Text";
import styled from "styled-components";

export const LoadingPanel = (props: {
  show: boolean;
  showAuth?: boolean;
  relativePositioning?: boolean;
  disableAnimation?: boolean;
}) => {
  const {show, showAuth, relativePositioning, disableAnimation} = props;
  const [hide, setHide] = useState<boolean>(false);
  const [revealAuth, setRevealAuth] = useState<boolean>(false);
  const [opacity, setOpacity] = useState<number>(1);
  const {loggedInUser} = useCurrentUser();
  const {to} = useNavigation();

  useEffect(() => {
    if (!show) {
      setOpacity(0);
      setHide(true);
    } else if (show) {
      setOpacity(1);
      setHide(false);
    }
  }, [disableAnimation, show]);

  useEffect(() => {
    if (showAuth) {
      setTimeout(() => setRevealAuth(true), 1000);
    } else {
      setRevealAuth(false);
    }
  }, [showAuth]);

  return (
    <LoadingPanelContainer
      position={!relativePositioning ? "fixed" : undefined}
      hide={hide}
      opacity={opacity}
    >
      <ImageContainer>
        <img src={logo} alt={"Villa"} />
      </ImageContainer>
      {!revealAuth && (
        <SpinnerContainer>
          <LoadingPanelSpinner size={"2rem"}>
            <AiOutlineLoading3Quarters color={WHITE} />
          </LoadingPanelSpinner>
        </SpinnerContainer>
      )}
      {revealAuth &&
        (loggedInUser ? (
          <LoggedInAs
            email={loggedInUser.email}
            logOut={() => logOut().then(() => to(NavigationPaths.LOGIN))}
          />
        ) : (
          <TextWrapper>
            <Text
              style={{
                marginTop: "16px",
                textAlign: "center",
              }}
              text={"Login or Sign Up"}
              links={[
                {
                  replacementKey: "Login",
                  onClick: () => to(NavigationPaths.LOGIN),
                },
                {
                  replacementKey: "Sign Up",
                  onClick: () => to(NavigationPaths.INTAKE),
                },
              ]}
            />
          </TextWrapper>
        ))}
    </LoadingPanelContainer>
  );
};

const TextWrapper = styled.div`
  a {
    color: WHITE !important;
  }
  span {
    color: WHITE !important;
  }
`;
