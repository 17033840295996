import React, {useState} from "react";
import MarketingChannelForm from "../../1-container/forms/MarketingChannelForm";
import {DefaultError} from "../../2-component/Input/generic/DefaultError";

const ConfirmMarketingChannel = (props: {
  email: string;
  onSubmit: (marketing_channel?: string) => void;
  onSkip?: () => void;
}) => {
  const {onSkip, onSubmit} = props;
  const [localError, setLocalError] = useState<string | undefined>();
  const handleSubmit = async (values: any) => {
    const marketingChannel = values["marketing_channel"];
    try {
      return onSubmit(marketingChannel);
    } catch (error: any) {
      setLocalError(error.message);
    }
  };

  return (
    <>
      <DefaultError id={"confirm-marketing-channel-error"} error={localError} />
      <MarketingChannelForm
        onSubmit={handleSubmit}
        buttons={[
          {
            id: "skip-marketing-channel",
            label: "Skip",
            type: "button",
            theme: "secondary",
            onClick: onSkip,
          },
          {
            id: "confirm-marketing-channel",
            label: "Confirm",
            type: "submit",
          },
        ]}
      />
    </>
  );
};

export default ConfirmMarketingChannel;
