import React from "react";
import Text from "../../2-component/Text";
import BusinessForm from "../../1-container/forms/BusinessForm";
import {FormProps} from "../../2-component/Form/interface";
import {ContentWrapper} from "./style";
import {CreateLeadSchema} from "@natomas-org/villa-nexus-client";
import {IAddressDetails} from "@natomas-org/core";
import {InputLibrary} from "../../1-container/forms/library/inputs";
import {useAddress} from "../../../components/_shared/hooks/useAddress";

interface ConfirmBusinessContentProps extends FormProps {
  schema?: CreateLeadSchema;
}

const getInputs = (
  defaultFirst?: string,
  defaultLast?: string,
  defaultAddress?: IAddressDetails
) => {
  return [
    {...InputLibrary.firstNameInput, row: 1, defaultValue: defaultFirst},
    {...InputLibrary.lastNameInput, row: 1, defaultValue: defaultLast},
    InputLibrary.phoneInput,
    {...InputLibrary.addressInput, defaultValue: defaultAddress?.full_address},
    InputLibrary.propertyTypeInput,
    InputLibrary.companyNameInput,
    InputLibrary.marketingConsentCheckbox,
  ];
};
const ConfirmBusinessContent = (props: ConfirmBusinessContentProps) => {
  const {schema, ...form} = props;
  const {url, publicAddress} = useAddress();
  const inputs = getInputs(
    schema?.["first_name"],
    schema?.["last_name"],
    url ?? publicAddress ?? undefined
  );
  return (
    <ContentWrapper>
      <Text
        text={
          "Our team can work with you to assess feasibility, design, cost, and ROI."
        }
        style={{
          textAlign: "center",
          fontSize: "14px !important",
          lineHeight: "18px !important",
        }}
      />
      <BusinessForm {...form} inputs={inputs} />
    </ContentWrapper>
  );
};

export default ConfirmBusinessContent;
