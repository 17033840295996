import {InputProps} from "../../../2-component/Input/interface";

export const InputLibrary: Record<
  | "phoneInput"
  | "addressInput"
  | "companyNameInput"
  | "propertyTypeInput"
  | "tosPrivacyCheckbox"
  | "marketingConsentCheckbox"
  | "emailInput"
  | "firstNameInput"
  | "lastNameInput"
  | "createPasswordInput"
  | "passwordInput",
  InputProps
> = {
  phoneInput: {
    id: "phone-number",
    label: "Phone number",
    type: "phone",
    autoComplete: "phone",
    required: true,
  },
  addressInput: {
    id: "project-address",
    label: "Project address",
    type: "address",
    placeholder: "",
    required: true,
  },
  companyNameInput: {
    id: "company-name",
    label: "Company name",
    type: "text",
    placeholder: "",
    required: true,
  },
  tosPrivacyCheckbox: {
    id: "tos-privacy",
    label: "I agree to the Terms of Use and Privacy Policy",
    type: "checkbox",
    textProps: {
      text: "I have read, understood and agree to the Terms of Use, and I have read and acknowledge the Privacy Policy, and I wish to create my account.",
      style: {
        lineHeight: "20px",
      },
      links: [
        {
          replacementKey: "Privacy Policy",
          target: "_blank",
          theme: "bold",
          href: "https://villahomes.com/privacy-policy/",
        },
        {
          replacementKey: "Terms of Use",
          target: "_blank",
          theme: "bold",
          href: "https://villahomes.com/terms-of-use/",
        },
      ],
    },
    required: true,
  },
  marketingConsentCheckbox: {
    id: "marketing-consent",
    label:
      "Yes! Please contact me at this email address about future products or services.",
    type: "checkbox",
    defaultChecked: true,
    defaultValue: "true",
    textProps: {
      text: "Visit our Privacy Policy to learn about our information practices and your privacy rights. To opt out of future marketing and advertising at any time, please use the unsubscribe button at the bottom of our email communications.",
      style: {
        lineHeight: "20px",
      },
      links: [
        {
          replacementKey: "Privacy Policy",
          target: "_blank",
          href: "https://villahomes.com/privacy-policy/",
        },
      ],
    },
  },
  firstNameInput: {
    id: "first-name",
    label: "First name",
    type: "text",
    autoComplete: "given-name",
    required: true,
  },
  lastNameInput: {
    id: "last-name",
    label: "Last name",
    type: "text",
    autoComplete: "family-name",
    required: true,
  },
  emailInput: {
    id: "email",
    label: "Email address",
    type: "email",
    autoComplete: "email",
    required: true,
  },
  passwordInput: {
    id: "password",
    label: "Password",
    type: "password",
    autoComplete: "password",
    required: true,
  },
  createPasswordInput: {
    id: "password",
    label: "Password",
    type: "create-password",
    autoComplete: "password",
    required: true,
  },
  propertyTypeInput: {
    id: "property-type",
    label: "Property type",
    type: "select",
    defaultValue: "Single Family Home",
    selectionProps: [
      {
        label: "Single Family Home",
        value: "Single Family Home",
      },
      {
        label: "Apartment building(s)",
        value: "Apartment building(s)",
      },
      {
        label: "Duplex or Triplex",
        value: "Duplex or Triplex",
      },
      {
        label: "Large multi-structure housing development",
        value: "Large multi-structure housing development",
      },
      {
        label: "Vacant Lot",
        value: "Vacant Lot",
      },
      {
        label: "Other",
        value: "Other",
      },
    ],
  },
};
