import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
`;

export const AdditionalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
`;

export const Input = styled.input`
  margin-top: 4px;
  cursor: pointer;
  accent-color: #2e565d;
`;

export const Label = styled.label`
  color: #344054;
  /* Text sm/Medium */
  font-family: "Inter-Medium", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  flex-grow: 1;
  user-select: none;
  cursor: pointer;
  margin: 0;
`;
