import {INVENTORY_UNIT_PREFIX} from "./components/_shared/constants/labels";

export const ADMIN_CUSTOMER_DATABASE_PATH = "/admin/customers";
export const ADMIN_CUSTOMER_PATH = "/admin/customer";
export const ADMIN_CUSTOMER_PROJECT_PATH = "/admin/customer-project";
export const ADMIN_DEVELOPER_PATH = "/admin/dev";
export const ADMIN_DATA_PATH = "/admin/data";
export const ADMIN_EMPLOYEE_MANAGER_PATH = "/admin/employees";
export const ADMIN_PRODUCT_CATALOG_PATH = "/admin/products";
export const ADMIN_PRODUCT_INVENTORY_PATH = "/admin/inventory";
export const ADMIN_PRODUCT_CATALOG_ROUTING_PATH = "/admin/products/routing";
export const ADMIN_LOCATION_PATH = "/admin/location/:locationId";
export const ADMIN_LOCATIONS_PATH = "/admin/location";
export const ADMIN_PRODUCT_ID_PATH = "/admin/product/:productId";
export const ADMIN_PRODUCT_PATH = "/admin/product";
export const ADMIN_PRODUCT_INSTANT_QUOTE_LOCATION_PATH =
  "/admin/product/:productId/instant-quote/:locationId";
export const ADMIN_PRODUCT_INSTANT_QUOTE_PATH =
  "/admin/product/:productId/instant-quote";
export const ADMIN_PRODUCT_LINE_PATH = "/admin/product-line";
export const ADMIN_FACTORY_LINE_PATH = "/admin/factory-line";
export const ADMIN_COMPLETED_PROJECTS_PATH = "/admin/completed-projects";
export const ADMIN_FACTORY_LINE_INFO_PATH = "/admin/factory-line/info";
export const ADMIN_ROOT_PATH = "/admin";

export const CATALOG_ROOT_PATH = "/products";
export const CATALOG_INVENTORY_PATH =
  CATALOG_ROOT_PATH + "/" + INVENTORY_UNIT_PREFIX.toLowerCase();
export const DOCUMENT_PATH = "/document";
export const DOCUMENT_PDF_PATH = `${DOCUMENT_PATH}/pdf`;
export const DOCUMENT_PDF_PROPOSAL_PATH = `${DOCUMENT_PDF_PATH}/proposal`;
export const EMPTY_PATH = "/"
export const HOME_PATH = "/home";
export const PAYMENTS_PATH = "/payments";
export const START_PATH = "/start";
export const STUDIO_PATH = "/studio";
export const LOGIN_PATH = "/login";
export const SUMMARY_PATH = "/summary";
export const MAP_PATH = "/map";
export const QUIZ_PATH = "/quiz";
export const DEVELOPMENT_INTAKE_PATH = "/development";
